import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BlogPreview } from './BlogPreview';

const api_root = "https://api.alfey.aismartmarketing.com";

export interface IBlogPostData {
    title: string;
    postContent: string;
}

export function BlogPreviewForm() {
    console.log("Loading blog preview form");
    const { postId } = useParams();
    console.log(`Viewing post id ${postId}`);
    const [blogPostData, setBlogPostData] = useState<IBlogPostData>({ title: "", postContent: "" });

    useEffect(() => {
        async function loadPost() {
            const response = await fetch(api_root + "/generatepreview?postId=" + postId, {mode: 'cors'});
            console.log("Response success: " + response.ok);
            let blogData = await response.json() as IBlogPostData;
            setBlogPostData(blogData);
        }
        loadPost();
    }, []);

    return (
        <div>
            <div>Blog Preview</div>
            <BlogPreview title={blogPostData?.title} content={blogPostData?.postContent} />
            <label>Api Key</label>
            <input type="text"></input>
            <button>SUBMIT</button>
        </div>
    );
}