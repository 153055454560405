import { useState } from "react";
import { useParams } from "react-router-dom";

interface BlogPreviewProps {
    title: string
    content: string
}

export function BlogPreview({ title, content }: BlogPreviewProps) {
    return (
        <div>
            <div class-name='blog-title'>
                {title}
            </div>
            <div class-name='blog-body'>
                {content}
            </div>
        </div>

    );
}