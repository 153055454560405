import React from 'react';
import ReactRouter, { Outlet } from 'react-router';
import logo from './logo.svg';
import './App.css';

export function App() {
  return (
    <div className="App">
        AI Content Machine
        <Outlet/>
    </div>
  );
}

export default App;
