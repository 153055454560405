import { App } from './App';
import { RouteObject } from 'react-router-dom';
import { BlogPreviewForm } from './Components/BlogPreview/BlogPreviewForm';


export const AppRoutes: RouteObject[] = [
    {
        element: <App />,
        path: '/',
        children: [{
            path: '/preview',
            children: [{
                path: '/preview/:postId',
                element: < BlogPreviewForm />
            }]

        }]
    }
]